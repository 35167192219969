import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
      <h3 style={{fontWeight:'bolder', paddingTop:50}}>Privacy Policy</h3>
      <p style={{fontWeight:'bolder', paddingTop:20}}><strong>Afrobeat Lyrics Streaming App</strong> respects your privacy and is committed to protecting any information we may collect. This policy outlines what data is collected and how it's used when you use our app.</p>

      <h4 style={{fontWeight:'bolder', paddingTop:20}}>1. Information Collection</h4>
      <p>We do not collect any personally identifiable information from users. The Afrobeat Lyrics Streaming App does not require any sign-up, registration, or the creation of user profiles. You can install and use the app without providing any personal data.</p>

      <h4 style={{fontWeight:'bolder', paddingTop:20}}>2. Non-Personal Information</h4>
      <p>While using the app, we may collect non-personal information about the performance of the app, such as error logs or analytics data. This data is anonymous and helps us improve the functionality of the app.</p>

      <h4 style={{fontWeight:'bolder', paddingTop:20}}>3. Third-Party Services</h4>
      <p>We do not integrate any third-party services that collect personal data. However, the app may use third-party services like media players for audio streaming, but these services operate independently and have their own privacy policies.</p>

      <h4 style={{fontWeight:'bolder', paddingTop:20}}>4. Data Security</h4>
      <p>Since we do not collect personal information, we do not store or process any such data. Your use of the app is safe and private.</p>

      <h4 style={{fontWeight:'bolder', paddingTop:20}}>5. Changes to This Policy</h4>
      <p>We may update this privacy policy from time to time. Any changes will be reflected here and will take effect immediately upon being posted.</p>

      <h4 style={{fontWeight:'bolder', paddingTop:20}}>6. Contact Us</h4>
      <p>If you have any questions about this privacy policy, please contact us at <a href="mailto:eddynnadi@gmail.com">eddynnadi@gmail.com</a>.</p>
    </div>
  );
};

export default PrivacyPolicy;
