


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import '../style/defaultstyle.css';
import Footer from '../components/Footer';
// for the title
import { Helmet } from 'react-helmet';


import AdSenseAd from '../components/AdSenseAd';



function AllSongs() {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noMoreSongs, setNoMoreSongs] = useState(false);
  const navigate = useNavigate();

  const metaDescription = 'Enjoy Afrobeat lyrics from thousands of tracks, sync lyrics to music, and explore the world of Afrobeat like never before.';

  const loadSongs = async () => {
    if (noMoreSongs || loading) return;
    setLoading(true);
    try {
      const response = await axios.get(`https://afrobeatlyric.com/api/songlyrics/?page=${page}`);
      const newSongs = response.data.results;
      if (newSongs.length === 0) {
        setNoMoreSongs(true);
      } else {
        const uniqueSongs = [...new Map([...songs, ...newSongs].map(song => [song.id, song])).values()];
        setSongs(uniqueSongs);
      }
    } catch (error) {
      console.error('Error loading songs:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSongs();
  }, [page]);

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };



     // Increment play count function
     const incrementPlayCount = async (songId) => {
      const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1]; // Retrieve csrftoken from cookies
  
  
      try {
          const response = await axios.post(
              `https://afrobeatlyric.com/api/increment_play_count/${songId}/`,
              {}, // Empty payload
              {
                  headers: {
                      'X-CSRFToken': csrfToken, // Include CSRF token in headers
                  },
                  withCredentials: true, // Ensure cookies are sent with the request
              }
          );
          console.log("Play count updated:", response.data.play_count);
      } catch (error) {
          console.error("Error updating play count:", error);
      }
  };



const handleSongSelect = async (song) => {
  await incrementPlayCount(song.id); // Increment play count
  
  // Format the artist name and song title for the URL
  const formattedTitle = song.title.replace(/\s+/g, '-').toLowerCase();  // Replace spaces with hyphens
  const formattedArtist = song.artist.replace(/\s+/g, '-').toLowerCase();

  navigate(`/songlyrics/${formattedArtist}/${formattedTitle}/${song.id}`); // Navigate to the new URL
};



  return (
    <div
      className="items-center justify-center h-screen "
      style={{
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'linear-gradient(to right, #08090D, #1A373F, #121E2A)',
      }}
    >

      <Helmet>
        <title>Afrobeat Lyrics Streaming App</title>
        <meta name="description" content={metaDescription} />
      </Helmet>


      <div className='max-w-8xl mx-auto pb-20'>
        <div className='border-b border-gray-500 pb-10'>
            <Navbar />
        </div>
        
        <div className='max-w-4xl mx-auto md:mt-16 p-5'>
          <h2 className="font-orbitron font-bold text-white/90 text-2xl mb-12 mt-14">
            Play List
          </h2>
          {songs.map((song) => (
            <div key={song.id} className='pb-5 mb-5 border-b border-gray-500 text-white'>
              <div className='cursor-pointer' onClick={() => handleSongSelect(song)}>
                <div className="flex justify-between">
                  <div className='flex'>
                    <img src={song.artist_image} className='pr-4' alt="Artist"
                         style={{height: '50px', width:'70px',borderRadius: '50%'}} />
                    <div>
                      <p className='font-bold'>{song.title}</p>
                      <p>{song.artist} {song.artist_feature}</p>
                    </div>
                  </div>
                  <div className='flex gap-4 items-center'>
                    <p className='font-semibold'>{song.duration_formatted || "00:00"}</p>
                    <i className="bi bi-play-circle text-2xl text-[#5bd8bd]"></i>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {loading && <p>Loading...</p>}
          {!loading && !noMoreSongs && (
            <button onClick={handleLoadMore} className="fancy-button2 text-white">Load More</button>
          )}
          {noMoreSongs && <p className='text-white'>No more songs to display.</p>}
        </div>
      </div>




      {/* Google Adsense Ad */}
      <AdSenseAd adClient="ca-pub-9715062602583366" adSlot="6487973641" />
      <Footer/>
    </div>
  );
}

export default AllSongs;







