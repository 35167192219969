import React from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Artists from '../components/Artists';
import Playlist from '../components/Playlist';
import Footer from '../components/Footer';

import AdSenseAd from '../components/AdSenseAd';

// for the title
import { Helmet } from 'react-helmet';


function HomePage(props) {

  const metaDescription = 'Enjoy Afrobeat lyrics from thousands of tracks, sync lyrics to music, and explore the world of Afrobeat like never before.';

    return (
          <div className=" items-center justify-center h-screen"
          style={{
            height: 'auto', // Full height
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'linear-gradient(to right, #08090D, #1A373F, #121E2A)',
          }}
          >
            <Helmet>
              <title>Afrobeat Lyrics Streaming App</title>
              <meta name="description" content={metaDescription} />
            </Helmet>
            
            <div className='max-w-8xl mx-auto'>
              <Navbar/>

              <HeroSection/>

              <Artists/>


              {/* Google Adsense Ad */}
              {/* <AdSenseAd adClient="ca-pub-9715062602583366" adSlot="6487973641" /> */}

              <Playlist/>

              

            </div>


            <Footer/>
            
      
    </div>
    );
}

export default HomePage;