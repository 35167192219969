import React, { useEffect, useRef } from 'react';

const AdSenseAd = ({ adClient, adSlot }) => {
  const adRef = useRef(null);

  useEffect(() => {
    if (adRef.current) {
      // Ensure the ad is initialized only once
      if (!adRef.current.getAttribute('data-ad-initialized')) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
          adRef.current.setAttribute('data-ad-initialized', 'true'); // Mark as initialized
        } catch (error) {
          console.error('AdSense Error:', error);
        }
      }
    }
  }, []);

  return (
    <ins
      ref={adRef}
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client={adClient}
      data-ad-slot={adSlot}
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default AdSenseAd;
