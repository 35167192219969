


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/VerticalSlider.css';

function HeroSection() {
  const [topSongs, setTopSongs] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);

  useEffect(() => {
    const fetchTopSongs = async () => {
      try {
        let url = 'https://afrobeatlyric.com/api/songlyrics/';
        let allTopSongsData = [];

        while (url) {
          const response = await axios.get(url);
          const topSongsData = response.data.results.filter(song => song.top_song === 'TopSong');
          allTopSongsData = [...allTopSongsData, ...topSongsData];
          setNextPageUrl(response.data.next);

          if (!response.data.next) break;
          url = response.data.next;
        }

        setTopSongs(allTopSongsData);
        
        
      } catch (error) {
        console.error("Error fetching top song data:", error);
      }
    };

    fetchTopSongs();
  }, []);

 

  if (!topSongs.length) {
    return <div>Loading...</div>;
  }

  const topSong = topSongs[0];

  return (
    <section className="max-w-8xl mx-auto px-4 pt-24 md:pt-36">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="order-last md:order-first w-full md:w-3/5">
          <img 
            src={topSong.artist_image} 
            alt="Hero Image" 
            className="w-screen h-60 md:w-screen md:h-2/3 rounded-2xl shadow-lg"
          />
        </div>

        <div className="w-full md:w-2/5 md:mt-10">
          <h2 className="font-orbitron font-bold text-white/90 text-[clamp(33px,3.167vw,40px)] md:text-[clamp(43px,3.177vw,80px)] leading-[120%] tracking-tight">
            Afrobeat Lyrics Streaming
          </h2>

          <div className='flex gap-6'>
            <div className='flex justify-between  pr-5 w-40 rounded-2xl mt-5 text-white' style={{backgroundImage: 'linear-gradient(to right, #455961 70%, #344A53)'}}>
              <img 
                src={topSong.artist_image}
                alt={topSong.artist}
                className="rounded-2xl shadow-lg"
                style={{ width: '50px', height: '50px' }}
              />
              <div>
                <p>Artist</p>
                <h2 className='font-bold'>{topSong.artist}</h2>
              </div>
            </div>

            <div className='flex justify-between pl-2 pr-5 w-40 rounded-2xl mt-5 text-white' style={{backgroundImage: 'linear-gradient(to right, #455961 70%, #344A53)'}}>
              <div>
                <p>Song Title</p>
                <h2 className='font-bold'>{topSong.title}</h2>
              </div>
            </div>
          </div>

          <div className='flex justify-between py-1 px-5 w-36 rounded-2xl mt-5 text-white' style={{backgroundImage: 'linear-gradient(to right, #455961 70%, #344A53)'}}>
            <div className='flex gap-4'>
              <p>Duration: </p>
              <p>{topSong.duration_formatted || "00:00"}</p>
            </div>
          </div>

          <div className='flex'>
            <h2 className='font-orbitron mt-8 text-sm md:text-xl justify-center  font-bold mr-8' style={{color:'#5bd8bd'}}>Our Top Song / Artist At the moment</h2>
          </div>

         
        </div>

        {/* Top Songs Slider */}
        <div className="hidden md:block h-eddh w-full md:w-52 overflow-hidden">
          <div className="slider-content">
            {[...topSongs, ...topSongs].map((song, index) => (
              <div key={index} className="slide">
                <img
                  src={song.artist_image}
                  className="rounded-2xl w-32"
                  alt={song.artist}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
    
  );
}

export default HeroSection;
