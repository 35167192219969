



import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SongLyricsScreen from './webfolder/pages/SongLyricsScreen';
import HomePage from './webfolder/pages/HomePage';
import AllSongs from './webfolder/pages/AllSongs';
import SearchResults from './webfolder/pages/SearchResults';
import PrivacyPolicy from './webfolder/pages/PrivacyPolicy';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage/>} />
        {/* <Route path="/songlyrics/:id" element={<SongLyricsScreen />} /> */}
        <Route path="/songlyrics/:artist/:title/:id" element={<SongLyricsScreen />} />
        <Route path="/all-songs" element={<AllSongs />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Add this line */}
      </Routes>
    </Router>
  );
}

export default App;