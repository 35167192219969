


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../style/HorizontalSlide.css';

function Artists() {
  const [artists, setArtists] = useState([]);
  const [allSongs, setAllSongs] = useState([]);
  const [selectedArtistSongs, setSelectedArtistSongs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState(null); // To track pagination
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        let url = 'https://afrobeatlyric.com/api/songlyrics/';
        let allSongsData = [];

        while (url) {
          const response = await axios.get(url);
          const songsData = response.data.results; // Assuming the API returns 'results' as an array of songs
          allSongsData = [...allSongsData, ...songsData];

          // Set the next page URL for pagination (if it exists)
          setNextPageUrl(response.data.next);

          // Create a unique list of artists
          const uniqueArtists = Array.from(new Set(allSongsData.map(song => song.artist)))
            .map(artistName => {
              const artistSong = allSongsData.find(song => song.artist === artistName);
              return {
                artist: artistName,
                artist_image: artistSong.artist_image, // Take the image from the first occurrence
              };
            });

          setArtists(uniqueArtists);
          setAllSongs(allSongsData); // Update all songs
          
          // If there is no next page URL, break the loop
          if (!response.data.next) break;
          url = response.data.next;
        }
      } catch (error) {
        console.error("Error fetching songs:", error);
      }
    };

    fetchSongs();
  }, []);


    // Increment play count function
    const incrementPlayCount = async (songId) => {
      const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1]; // Retrieve csrftoken from cookies
  
  
      try {
          const response = await axios.post(
              `https://afrobeatlyric.com/api/increment_play_count/${songId}/`,
              {}, // Empty payload
              {
                  headers: {
                      'X-CSRFToken': csrfToken, // Include CSRF token in headers
                  },
                  withCredentials: true, // Ensure cookies are sent with the request
              }
          );
          console.log("Play count updated:", response.data.play_count);
      } catch (error) {
          console.error("Error updating play count:", error);
      }
  };


  // Filter songs by artist
  const handleArtistClick = (artist) => {
    const filteredSongs = allSongs.filter(song => song.artist === artist);
    setSelectedArtistSongs(filteredSongs);
    setShowPopup(true); // Open modal with filtered songs
  };


 

  const handleSongSelect = async (song) => {
    await incrementPlayCount(song.id); // Increment play count
  
    // Format the artist name and song title for the URL
    const formattedTitle = song.title.replace(/\s+/g, '-').toLowerCase();  // Replace spaces with hyphens
    const formattedArtist = song.artist.replace(/\s+/g, '-').toLowerCase();
  
    navigate(`/songlyrics/${formattedArtist}/${formattedTitle}/${song.id}`); // Navigate to the new URL
    setShowPopup(false); // Close popup on song selection
  };


  

  return (
    <section className="max-w-8xl mx-auto mt-14 md:-mt-40">
      <div>
        <h2 className=" flex justify-center md:justify-start font-orbitron font-bold text-white/90 text-xl md:text-2xl tracking-tight mb-6">
          Afrobeat Artist Collections
        </h2>
        <div className="slider-container">
          <div className="slider">
            {/* Duplicate artists array for seamless scrolling */}
            {[...artists, ...artists].map((artist, index) => (
              <div key={index} className="slide" onClick={() => handleArtistClick(artist.artist)}>
                <img
                  src={artist.artist_image}
                  className="w-60 rounded-tl-3xl rounded-tr-3xl cursor-pointer"
                  alt={artist.artist}
                />
                <div className="info-box">
                  <p className="font-bold text-sm">{artist.artist}</p>
                  <p className=" text-sm mt-1">Collections</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Popup Modal for Artist Songs */}
      {showPopup && (
        <div className="popup-overlay">
        <div className="popup-content px-1 py-4 md:p-5" style={{backgroundColor:'#1A373F'}}>

          <h3 className="font-orbitron  text-white/90 text-sm mb-5">Songs by {selectedArtistSongs[0]?.artist}</h3>
          <button onClick={() => setShowPopup(false)} className="close-popup">X</button>

          <div className='bg-black/50 rounded-3xl border-blue-300 text-white p-1 md:p-8'>
            {selectedArtistSongs.map((song) => (
              <div key={song.id} className='px-1 py-2 mb-2 border-b border-gray-500'>
                <div className='cursor-pointer' onClick={() => handleSongSelect(song)}>
                  <div className="flex justify-between">
                    <div className='flex'>
                      <img src={song.artist_image} className='pr-4' alt="Artist"
                           style={{height: '50px', width:'70px',borderRadius: '50%'}} />
                      <div>
                        <p className='font-bold'>{song.title}</p>
                        <p>{song.artist} {song.artist_feature}</p>
                      </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                      <p>{song.duration_formatted || "00:00"}</p>
                      <i className="bi bi-play-circle text-2xl text-red-500"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
      )}

      {/* Optionally, display a "Load More" button if there are more songs to load */}
      {nextPageUrl && (
        <button onClick={() => setNextPageUrl(nextPageUrl)} className="load-more-btn">
          Load More Songs
        </button>
      )}
    </section>
  );
}

export default Artists;









