

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import '../style/defaultstyle.css';
import Footer from '../components/Footer';

import AdSenseAd from '../components/AdSenseAd';

// for the title
import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router-dom';



function SearchResults() {
  const navigate = useNavigate();




  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  
  useEffect(() => {
    if (query) {
        const fetchResults = async () => {
          try {
              setLoading(true);
              const response = await axios.get(`https://afrobeatlyric.com/api/search/?search=${query}`);
              setResults(response.data.results); // Ensure `results` field is in response
          } catch (err) {
              setError('Error fetching results');
              console.error('Error:', err);
          } finally {
              setLoading(false);
          }
        };
      
      fetchResults();
    }
  }, [query]);
  
  if (loading) {
    return <p>Loading...</p>;
  }
  
  if (error) {
    return <p>{error}</p>;
  }




     // Increment search count function



    const incrementSearchCount = async (songId) => {
      const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1]; // Retrieve csrftoken from cookies
  
  
      try {
          const response = await axios.post(
              `https://afrobeatlyric.com/api/search_play_count/${songId}/`,
              {}, // Empty payload
              {
                  headers: {
                      'X-CSRFToken': csrfToken, // Include CSRF token in headers
                  },
                  withCredentials: true, // Ensure cookies are sent with the request
              }
          );
          console.log("Play count updated:", response.data.play_count);
      } catch (error) {
          console.error("Error updating play count:", error);
      }
  };



      // Increment play count function
      const incrementPlayCount = async (songId) => {
        const csrfToken = document.cookie
            .split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1]; // Retrieve csrftoken from cookies
    
    
        try {
            const response = await axios.post(
                `https://afrobeatlyric.com/api/increment_play_count/${songId}/`,
                {}, // Empty payload
                {
                    headers: {
                        'X-CSRFToken': csrfToken, // Include CSRF token in headers
                    },
                    withCredentials: true, // Ensure cookies are sent with the request
                }
            );
            console.log("Play count updated:", response.data.play_count);
        } catch (error) {
            console.error("Error updating play count:", error);
        }
    };




  const handleSongSelect = async (song) => {
    await incrementSearchCount(song.id); // Increment search count
    await incrementPlayCount(song.id); // Increment play count
    // Format the artist name and song title for the URL
    const formattedTitle = song.title.replace(/\s+/g, '-').toLowerCase();  // Replace spaces with hyphens
    const formattedArtist = song.artist.replace(/\s+/g, '-').toLowerCase();
  
    navigate(`/songlyrics/${formattedArtist}/${formattedTitle}/${song.id}`); // Navigate to the new URL
  };

  const metaDescription = 'Enjoy Afrobeat lyrics from thousands of tracks, sync lyrics to music, and explore the world of Afrobeat like never before.';



  return (
    <div
      className="items-center justify-center h-screen"
      style={{
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'linear-gradient(to right, #08090D, #1A373F, #121E2A)',
      }}
    >

      <Helmet>
        <title>{results.length > 0 ? `${results[0].artist} - ${results[0].title} Music Lyrics` : 'Afrobeat Lyrics Streaming App'}</title>
        <meta name="description" content={results.length > 0 ? `${results[0].lyrics_text.substring(0, 150)}...` : metaDescription} />
      </Helmet>


      <div className='max-w-8xl mx-auto pb-20'>
        <div className='border-b border-gray-500 pb-10'>
            <Navbar />
        </div>
        
        <div className='max-w-4xl mx-auto h-60 pl-5 pr-5 md:mt-28'>
          <h2 className="font-orbitron font-bold text-white/90 text-xl mb-2 mt-14 pb-7 border-b border-gray-500">
          Search Results for "{query}"
          </h2>

          {results.length === 0 ? (
            <p className="font-orbitron font-bold text-white/90 text-sm mb-12 mt-14">No results found</p>
          ) : (
            results.map((song, index) => (
              <div key={song.id} className='pl-6 pb-5 mb-5 border-b border-gray-500 text-white'>
              <div className='cursor-pointer' onClick={() => handleSongSelect(song)}>
                <div className="flex justify-between">
                  <div className='flex'>
                
                    <div>
                      <p className='font-bold'>{song.title}</p>
                      <p>{song.artist} {song.artist_feature}</p>
                    </div>
                  </div>
                  <div className='flex gap-4 items-center'>
                    <p className='font-semibold'>{song.duration_formatted || "00:00"}</p>
                    <i className="bi bi-play-circle text-2xl text-red-500"></i>
                  </div>
                </div>
              </div>
            </div>
            ))
          )}

          
        </div>
      </div>

{/* Google Adsense Ad */}
<AdSenseAd adClient="ca-pub-9715062602583366" adSlot="6487973641" />
      
      <Footer/>
    </div>
  );
}

export default SearchResults;

















