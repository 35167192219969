import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaDownload } from 'react-icons/fa';

import play from '../images/play.png'

const Footer = () => {
  return (
    <footer className="bg-black/50 ">
      <div style={styles.sectionContainer}>
        {/* About Section */}
        <div style={styles.aboutSection}>
          <h2 className="font-orbitron font-bold text-white/90 text-xl mb-2 mt-14 pb-7  ">Afrobeat Lyrics Streaming App</h2>
          <p  className="font-orbitron  text-white/90 text-sm leading-relaxed  ">
           Enjoy Afrobeat lyrics from collection of tracks,
            sync lyrics to music, and explore the world of Afrobeat like never before.
          </p>
        </div>

        {/* Social Media Links */}
        <div style={styles.socialMediaSection}>
          <h2 className="font-orbitron font-bold text-white/90 text-xl mb-2 mt-14 pb-7  ">Follow Us</h2>
          <div style={styles.socialIcons}>
            <a href="#" target="_blank" rel="noopener noreferrer" style={styles.icon}>
              <FaFacebook />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" style={styles.icon}>
              <FaTwitter />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" style={styles.icon}>
              <FaInstagram />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer" style={styles.icon}>
              <FaYoutube />
            </a>
          </div>
        </div>

        {/* Mobile App Installation */}
        <div style={styles.mobileAppSection} >
          <h2 className="font-orbitron font-bold text-white/90 text-xl mb-2 mt-14 pb-7 ">Get The Mobile App</h2>
          <a href='https://play.google.com/store/apps/details?id=com.afrobeatlyrics'>
          <img src={play}  className="mx-auto"  />
          </a>
        </div>

        {/* Subscription for Lyrics Notifications */}
        <div style={styles.subscriptionSection}>
          <h2 className="font-orbitron font-bold text-white/90 text-xl mb-2 mt-14 pb-7 flex justify-center">Stay Updated</h2>
          <form className='flex pl-3 pr-3' >
            <input
              type="email"
              placeholder="Enter your email"
              style={styles.emailInput}
              required
              className='text-gray-600'
            />
            <button type="submit" style={styles.subscribeButton}>
              Subscribe
            </button>
          </form>
        </div>
      </div>

      <div style={styles.footerBottom}>
        <p>&copy; {new Date().getFullYear()} Afrobeat Lyrics Streaming. All rights reserved.</p>
      </div>
    </footer>
  );
};

const styles = {
  footerContainer: {
    color: '#FFFFFF',
    padding: '40px 0',
  },
  sectionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    margin: 'auto',
  },
  aboutSection: {
   
    marginBottom: '20px',
    textAlign: 'center',
    flex: 1,
    minWidth: '250px',
  },
  socialMediaSection: {
    marginBottom: '20px',
    textAlign: 'center',
    flex: 1,
    minWidth: '250px',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    marginTop: '10px',
  },
  icon: {
    fontSize: '1.5em',
    color: '#FFFFFF',
  },
  mobileAppSection: {
    marginBottom: '20px',
    textAlign: 'center',
    flex: 1,
    minWidth: '250px',
    
  },
  downloadButton: {
    backgroundColor: '#00A4E4',
    color: '#FFFFFF',
    border: 'none',
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
    gap: '8px',
  },
  subscriptionSection: {
    textAlign: 'center',
    flex: 1,
    marginBottom: '20px',
    minWidth: '250px',
  },
  subscriptionForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
  },
  emailInput: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #CCCCCC',
    width: '100%',
    
  },
  subscribeButton: {
    backgroundColor: '#FF4500',
    color: '#FFFFFF',
    border: 'none',
    padding: '10px 10px',
    fontSize: '16px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  footerBottom: {
    textAlign: 'center',
    marginTop: '40px',
    fontSize: '16px',
    color:'#fff'
  },
};

export default Footer;
