


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import logoimg from '../images/logo.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("Home");
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Redirect to the search results page with the search query as a query parameter
      navigate(`/search?query=${searchQuery}`);
    }
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-10 text-white md:pt-6" style={{backgroundImage: 'linear-gradient(to right, #08090D, #1A373F, #121E2A)',}}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            {/* Logo */}
            <a href="/">
            <img 
              src={logoimg}
              alt="Logo" 
              className="w-16 mr-8" 
            />
            </a>

            {/* Desktop Menu */}
            <div className="hidden md:flex space-x-4">
              <a
                href="/"
                onClick={() => handleMenuClick("Home")}
                className={`text-lg font-semibold ${
                  activeMenu === "Home" ? "text-[#5bd8bd]" : "hover:text-green-200"
                }`}
              >
                Home
              </a>
             
              <a
                href="/all-songs"
                onClick={() => handleMenuClick("Release")}
                className={`text-lg font-semibold ${
                  activeMenu === "Release" ? "text-[#5bd8bd]" : "hover:text-green-200"
                }`}
              >
                Play List
              </a>
            </div>
          </div>

          {/* Centered Search Input */}
          <div className="relative flex-grow max-w-lg mx-4">
            <form onSubmit={handleSearchSubmit}>
              <input
                type="text"
                placeholder="Search Song or Artist"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full bg-white bg-opacity-10 border border-white border-opacity-30 text-white rounded-full pl-4 pr-10 py-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-white placeholder-gray-200"
              />
              <button type="submit" className="absolute right-3 top-3 text-white">
                <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M10 2a8 8 0 015.292 13.707l5.292 5.293-1.414 1.414-5.293-5.292A8 8 0 1110 2zm0 2a6 6 0 100 12 6 6 0 000-12z" />
                </svg>
              </button>
            </form>
          </div>

          {/* Social Media Icons (Desktop Only) */}
          <div className="hidden md:flex space-x-4">
            <a href="#" aria-label="Facebook" className="text-white hover:text-green-200">
              <FaFacebook size={20} />
            </a>
            <a href="#" aria-label="Twitter" className="text-white hover:text-green-200">
              <FaTwitter size={20} />
            </a>
            <a href="#" aria-label="Instagram" className="text-white hover:text-green-200">
              <FaInstagram size={20} />
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-300 hover:text-white focus:outline-none"
            >
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-700">
          <a
            href="/"
            onClick={() => handleMenuClick("Home")}
            className={`block px-4 py-2 text-sm ${
              activeMenu === "Home" ? "text-[#5bd8bd]" : "text-white hover:bg-gray-600"
            }`}
          >
            Home
          </a>
        
          <a
            href="/all-songs"
            onClick={() => handleMenuClick("Release")}
            className={`block px-4 py-2 text-sm ${
              activeMenu === "Release" ? "text-[#5bd8bd]" : "text-white hover:bg-gray-600"
            }`}
          >
            Play List
          </a>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
